import React, {Component} from 'react';

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <a href="/">Home</a>
        <a href="/i/tos" style={{marginLeft: 8}}>Terms of Service</a>
        <h1>Our perspective on security and privacy</h1>
        <p>No site can be perfectly secure. And privacy ethics are arcane and confusing. So where does that leave us?</p>
        <p>We are vigilant, responsible and cunning stuards of your personal data.
        I've been working in technology long enough, in social media, ecommerce, and others,
        to have good idea of the security threats facing consumers out there, and the
        temptations facing companies to monitize user data.
        </p>
        <h2>Responsible capitalization</h2>
        <p>We do not want to be a mega-campus tech company. I don't aspire to be Mark Zuckerberg.
        But we do want to site to run reliably, be modern and easy to use. Me and my friends have made millions of dollars in tech.
        Some of it more ethically than others. I will not make the same mistakes as Facebook has.
        Brands are desperate to find out what you are liking and responding too. But
        they will have to find out the same way everybody else does. Reading the frontpage
        as real people, and engaging in the comment sections. We will never give certain
        individuals or groups special access to our content for money.
        </p>
        <h2>Not being maniupaltive</h2>
        <p>We  strive to show you content based on your previous usage that will be the
        most compelling and relevant to you. But we know 'engagement' is a dangerous metric and we want to
        give you relevant information coming from groups you're not used to, and not
        radicalizing you. We take our user's mental health seriously and if you tell us
        you don't like the type of content we are showing you, we will do our best to change it.</p>
        <h2>Staying vigilant</h2>
        <p>All the right intentions in the world don't help if your defenses get breached.
        Our engineers stay engaged in the broader security community, and follow
        latest best practices. We see threat coming before they get here, and stay
        contrarian in our thinking so we can detect silent leaks</p>
      </div>
    );
  }
}
