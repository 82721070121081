import {uploadFile} from './upload';

export function uploadPost(opts) {
  return dispatch => {
    uploadFile({
      uri: window.location.origin + '/api/upload',
      body: {content: opts.content}
    }, this.state.file)
    /*request({
      baseUrl: window.location.origin,
      method: 'post',
      uri: '/api/post',
      headers: {Content: 'Keep-Alive'},
      body: {content: this.state.input},
      json: true
    }, (err, resp, body) => {
      this.getPosts();
    });*/
  }
}

function reduxXHR(name, requestOpts) {
  return req => dispatch => new Promise((resolve, reject) => {
    dispatch({
      type: name + '_START',
      payload: {req}
    });
    fetch(window.location.origin + requestOpts.uri, {
      ...requestOpts,
      body: JSON.stringify(req),
      headers: {
        'Content-Type': 'application/json',
        ...requestOpts.headers
      },
      credentials: "same-origin"
    })
    .then(r => {
      if (!r.ok) {
        return r.json().then(jr => {
          throw Error(jr.err)
        });
      }
      return r.json();
     })
    .then(res => {
      dispatch({
        type: name + '_SUCCESS',
        payload: {req, res}
      });
      resolve({req, res});
    })
    .catch(err => {
      dispatch({
        type: name + '_FAILURE',
        payload: {req, err: err.message}
      });
      reject({err, req});
      return null;
    })
  });
}

function CRUDOps({
  itemName, apiName, collectionName,
  collectionItemName}) {
  return {
    create: reduxXHR(`CREATE_${itemName}`, {
      uri: `/api/${apiName}`,
      method: 'post'
    }),
    update: reduxXHR(`UPDATE_${itemName}`, {
      uri: `/api/${apiName}`,
      method: 'put'
    }),
    read: opts => reduxXHR(`GET_${itemName}`, {
      uri: `/api/${apiName}/${opts.id}`
    })(),
    delete: reduxXHR(`DELETE_${itemName}`, {
      uri: `/api/${apiName}`,
      method: 'delete'
    }),
    collection: opts => reduxXHR(`GET_${collectionItemName}`, {
      uri: `/api/${collectionName}${opts ? `?q=${JSON.stringify(opts)}` : ''}`
    })(),
  }
}

const {
  create: createPost,
  read: getPost,
  delete: deletePost,
  collection: getPosts
} = CRUDOps({
  itemName: 'POST',
  apiName: 'post',
  collectionName: 'posts',
  collectionItemName: 'POSTS'
});

const {
  create: createSession,
  read: getSession,
  delete: deleteSession
} = CRUDOps({
  itemName: 'SESSION',
  apiName: 'session'
});

const {
  create: createRoom,
  read: getRoom,
  delete: deleteRoom,
  collection: getRooms
} = CRUDOps({
  itemName: 'ROOM',
  apiName: 'room',
  collectionName: 'rooms',
  collectionItemName: 'ROOMS'
});

const {
  create: createComment,
  read: getComment,
  delete: deleteComment,
  collection: getComments
} = CRUDOps({
  itemName: 'COMMENT',
  apiName: 'comment',
  collectionName: 'comments',
  collectionItemName: 'COMMENTS'
});

const {
  create: createUser,
  read: getUser,
  delete: deleteUser,
  collection: getUsers,
  update: updateUser
} = CRUDOps({
  itemName: 'USER',
  apiName: 'user',
  collectionName: 'users',
  collectionItemName: 'USERS'
});

const voteOnPost = reduxXHR('VOTE_ON_POST', {
  uri: '/api/vote',
  method: 'post'
})

const voteOnComment = reduxXHR('VOTE_ON_COMMENT', {
  uri: '/api/comment/vote',
  method: 'post'
})

const getMe = reduxXHR('GET_ME', {
  uri: '/api/me'
})

const login = reduxXHR('LOGIN', {
  uri: '/api/login',
  method: 'post'
})

const getMessages = reduxXHR('GET_MESSAGES', {
  uri: '/api/messages'
});

const markMessageAsRead = reduxXHR('MARK_AS_READ', {
  uri: '/api/messages/read',
  method: 'post'
});

const getUnreadCount = reduxXHR('GET_UNREAD_COUNT', {
  uri: '/api/messages/unreadCount'
});

const getKarma = reduxXHR('GET_KARMA', {
  uri: '/api/me/karma'
});

const getUserPosts = opts => reduxXHR('GET_USER_POSTS', {
  uri: `/api/user/${opts.userName}/posts`
})();

const updateUserRole = reduxXHR('UPDATE_USER_ROLE', {
  uri: '/api/user/role',
  method: 'post'
});

const getVerifications = reduxXHR('GET_VERIFICATIONS', {
  uri: '/api/user/verifications'
});

const createVerification = reduxXHR('CREATE_VERIFICATION', {
  uri: '/api/user/verification',
  method: 'post'
});

export {
  createPost, getPost, deletePost, getPosts,
  createRoom, getRoom, deleteRoom, getRooms,
  createSession, getSession, deleteSession,
  createComment, getComment, deleteComment, getComments,
  voteOnPost, getMe, voteOnComment,
  createUser, getUser, deleteUser, getUsers, updateUserRole,
  updateUser, login,
  getMessages, markMessageAsRead, getUnreadCount,
  getKarma, getUserPosts,
  createVerification, getVerifications
};
