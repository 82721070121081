import React from 'react';
import * as moment from 'moment';

const Timeago = ({date}) => {
  const myDate = moment(date);
  const nowDate = moment();
  let string;
  if (nowDate.diff(myDate, 'days') < 1) {
    if (nowDate.diff(myDate, 'hours') < 2) {
      string = `${nowDate.diff(myDate, 'minutes')} minutes ago`
    } else {
      string = `${nowDate.diff(myDate, 'hours')} hours ago`
    }
  } else {
    string = myDate.format('MM-DD-YYYY')
  }

  return (
    <span style={{
      color: 'grey',
      marginLeft: '4px'
    }}>
      {string}
    </span>
  );
};

export default Timeago;
