import {connect} from 'react-redux';
import CreatePostView from './view';
import {
  createPost
} from '../../actions/xhr-actions';
import {
  uploadPostFile
} from '../../actions/upload';

export default connect(state => state, {
  createPost,
  uploadPostFile
})(CreatePostView);
