import MeView from './me-view';
import {connect} from 'react-redux';
import {
  updateUser,
  getUsers,
  login,
  getMe,
  getMessages,
  markMessageAsRead,
  getKarma
} from '../../actions/xhr-actions';
import {
  undismissModal
} from '../../actions/user-actions';

export default connect(state => ({
  me: state.me.me,
  users: state.users.users,
  messages: state.messages.messages,
  karma: state.me.karma,
  modals: state.modals.modals
}), {
  updateUser,
  getUsers,
  login,
  getMe,
  getMessages,
  markMessageAsRead,
  getKarma,
  undismissModal
})(MeView);
