import AdminView from './user-view';
import {connect} from 'react-redux';
import {
  getUserPosts,
  getUsers,
  login
} from '../../actions/xhr-actions';
import {withRouter} from 'react-router';

export default connect(state => ({
  posts: state.userPosts.posts
}), {
  getUserPosts,
  getUsers,
  login
})(withRouter(AdminView));
