export const dismissNotification = (notifUuid) => {
  return {
    type: 'DISMISS_NOTIFICATION',
    payload: {
      notifUuid
    }
  }
}

export const dismissModal = (payload) => {
  return {
    type: 'DISMISS_MODAL',
    payload
  }
}

export const popModal = (payload) => {
  return {
    type: 'POP_MODAL',
    payload
  }
}

export const undismissModal = (payload) => {
  return {
    type: 'UNDISMISS_MODAL',
    payload
  }
}
