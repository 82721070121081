const defaultState = {
  comments: []
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'GET_COMMENTS_SUCCESS':
    return {
      ...state,
      comments: action.payload.res
    };
  case 'VOTE_ON_COMMENT_START':
    const digToVoteAndMutate = (c) => {
      if (c.id === action.payload.req.commentId) {
        c.votescore = parseInt(c.votescore || 0) + action.payload.req.value - (c.myVote ? c.myVote.value : 0);
        c.myVote = action.payload.req;
      }
      return {
        ...c,
        Children: (c.Children || []).map(child => digToVoteAndMutate(child))
      }
    }
    return {
      ...state,
      comments: state.comments.map(c => digToVoteAndMutate(c))
    }
  default:
    return state;
  }
}
