import React, {Component} from 'react';
const INTRO_VID_URI = "quipy-intro.mp4";

export default class IntroModal extends Component {
  render() {
    return (
      <div>
        <strong>Welcome to Quipy!</strong>
        <p>
          {'Greetings. We already created an account for you, '}
          <strong>{(this.props.me || {}).name}</strong>
          {'. Watch this intro video, then verify yourself and join the party!'}
        </p>
        <div style={{
          display: 'table',
          margin: '0 auto'
        }}>
          <video src={`/api/public-bucket/${INTRO_VID_URI}`} controls
            height="300px"></video>
        </div>
        <p>
          {'OK now you get the idea. Head '}
          <a href="/u/verify"
            onClick={() => {
              this.props.dismissModal({
                id: this.props.modal.id
              })
            }}>{'over here'}</a>
            {' to verify yourself.'}
        </p>
        <p>
          PSST if you really want to be a lurker first, you can get a <a href="/pass">24 hour pass</a>.
        </p>
      </div>
    );
  }
}
