import React, {Component} from 'react';
import {
  withRouter
} from "react-router";
import SimpleMDE from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

class CreatePost extends Component {
  constructor() {
    super();
    this.state = {

    };
    this.handleUpload = this.handleUpload.bind(this);
  }
  handleUpload(e) {
    this.setState({
      uploadInprogress: true,
      progressTotal: 100,
      progressLoaded: 1});
    this.props.uploadPostFile({

    }, e.target.files[0], {
      onprogress: e => {
        this.setState({
          progressTotal: e.total,
          progressLoaded: e.loaded
        });
      }
    }).then(fileAction => {
      const thumbFile = fileAction.payload.res[0];
      const fileName = thumbFile.mimetype.indexOf('video') > -1 ?
        `quip://video/${thumbFile.storageKey}` :
        `quip://file/${thumbFile.storageKey}`;
      this.setState({
        progressTotal: null,
        progressLoaded: null,
        uploadInprogress: false
      });
      return this.props.createPost({
        content: fileName,
        room: this.props.match.params.roomName
      });
    }).then((post) => {
      this.props.history.go(`/q/${this.props.match.params.roomName}`);
    });
  }
  renderProgressBar() {
    if (!this.state.uploadInprogress) {
      return null;
    }
    return (
      <div
        style={{
          width: '100%',
          height: '4px',
          backgroundColor: 'grey'
        }}>
        <div style={{
          height: '100%',
          width: `${Math.floor(Math.max(
            .05,
            this.state.progressLoaded / this.state.progressTotal
          ) * 100)}%`,
          transition: 'width 500ms',
          backgroundColor: 'blue'
        }} />
      </div>
    );
  }
  renderPhotoInput() {
    if (this.state.postType !== 'photo' &&
      this.state.postType !== 'video') {
      return null;
    }
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
        }}>
        <label>{'Post something!'}</label>
        <input type="file"
          onChange={this.handleUpload}
          value={this.state.file} />
        {this.renderProgressBar()}
      </form>
    );
  }
  renderLinkInput() {
    if (this.state.postType !== 'link') {
      return null;
    }
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          this.props.createPost({
            content: `${this.state.linkInput}%LINKDELINEATE%${this.state.textInput}`,
            room: this.props.match.params.roomName
          }).then(() => {
            this.props.history.push(`/q/${this.props.match.params.roomName}`)
          });
        }}>
        <div>
          <label>{'Link'}</label>
          <input type="text"
            onChange={e => this.setState({linkInput: e.target.value})}
            value={this.state.linkInput} />
        </div>
        <div>
          <label>{'Text'}</label>
          <input type="text"
            onChange={e => this.setState({textInput: e.target.value})}
            value={this.state.textInput} />
        </div>
        <button type="submit">{'Post'}</button>
      </form>
    );
  }
  renderTextInput() {
    if (this.state.postType !== 'text' &&
      this.state.postType !== 'link') {
      return null;
    }
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          this.props.createPost({
            content: `${this.state.titleInput}%TEXTDELINEATE%${this.state.bodyInput}`,
            room: this.props.match.params.roomName
          }).then(() => {
            this.props.history.push(`/q/${this.props.match.params.roomName}`)
          });
        }}>
        <div>
          <label>{'Title'}</label>
          <input type="text"
            onChange={e => this.setState({titleInput: e.target.value})}
            value={this.state.titleInput} />
        </div>
        <div>
          <label>{'Body'}</label>
          <SimpleMDE
            onChange={value => this.setState({bodyInput: value})}
            value={this.state.bodyInput} />
        </div>
        <button type="submit">{'Post'}</button>
      </form>
    );
  }
  renderTypeChoices() {
    if (this.state.postType) {
      return null;
    }
    return (
      <div>
        <a href="/">Home</a>
        <div>
          <label>
            {'Select a post type'}
          </label>
        </div>
        <a href="blank"
          style={{
            marginRight: 4
          }} onClick={e => {
          e.preventDefault();
          this.setState({postType: 'text'})
        }}>
          {'Text'}
        </a>
        <a href="blank"
          style={{
            marginRight: 4
          }} onClick={e => {
          e.preventDefault();
          this.setState({postType: 'link'})
        }}>
          {'Link'}
        </a>
        <a href="blank"
          style={{
            marginRight: 4
          }} onClick={e => {
          e.preventDefault();
          this.setState({postType: 'video'})
        }}>
          {'Video'}
        </a>
        <a href="blank"
          style={{
            marginRight: 4
          }} onClick={e => {
          e.preventDefault();
          this.setState({postType: 'photo'})
        }}>
          {'Photo'}
        </a>
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.renderTypeChoices()}
        {this.renderLinkInput()}
        {this.renderTextInput()}
        {this.renderPhotoInput()}
      </div>
    );
  }
}

export default withRouter(CreatePost);
