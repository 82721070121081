import React, {Component} from 'react';

export default class TOS extends Component {
  render() {
    return (
      <div>
        <a href="/">Home</a>
        <h1>Our terms of service</h1>
        <p>By using this site, you agree give up all rights now and in the future to everything related to your data.
        We may use this content to monetize and promote our website and do whatever else we determine. Everythng on this
        website is the compelte and sole property of Quipy</p>
        <p>We promise to strive to not use these rights to do damage to you. For more information on our thinking,
        see <a href="/privacy">our privacy policy.</a></p>
      </div>
    );
  }
}
