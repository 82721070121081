import React, {Component} from 'react';
import RoomView from './room-view';
import {connect} from 'react-redux';
import {
  getRooms,
  getPosts,
  deletePost,
  getUnreadCount,
  getKarma,
  getRoom,
  deleteRoom
} from '../../actions/xhr-actions';

export default connect(state => ({
  posts: state.posts.posts,
  rooms: state.rooms.rooms,
  arePostsLoading: state.posts.isLoading,
  me: state.me.me,
  unread: state.me.unread,
  room: state.rooms.room,
  karma: state.me.karma,
}), {
  getRooms,
  getPosts,
  deletePost,
  getUnreadCount,
  getKarma,
  getRoom,
  deleteRoom
})(RoomView);
