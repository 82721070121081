import React, {Component} from 'react';
import uuid from 'uuidv4';
import md5 from 'js-md5';
import {changeSession} from '../../session-cookie';
const quipySessKey = '_qsess';

function renderMessage(message) {
  const parsedMessage = message.split('quip://').join('http://localhost:3000/');
  return parsedMessage.split(' ').map(word => {
    if (word.indexOf('http') === 0) {
      return (
        <a href={word}>{word}</a>
      );
    }
    return word;
  }).reduce((a, word, i, arr) => {
    a.push(word);
    if (i < arr.length - 1) {

      a.push(' ')
    }
    return a;
  }, [])
}

export default class MeView extends Component {
  static defaultProps = {
    users: []
  }
  constructor() {
    super();
    this.state = {
      updateOpen: false,
      nameInput: '',
      pwInput: ''
    };
  }
  componentDidMount() {
    this.props.getMessages();
    this.props.getKarma();
  }
  loginAs() {
    const sessVal = uuid();
    this.props.login({
      name: this.state.loginUserInput,
      password: this.state.longPasswordInput,
      key: sessVal
    }).then(() => {
      document.cookie = `${quipySessKey}=${sessVal}; path=/`
      this.props.getMe();
    });
  }
  renderLogin() {
    if (!this.state.loginOpen) {
      return (
        <div><a href={"blank"}
          onClick={e => {
            e.preventDefault();
            this.setState({
              loginOpen: true
            });
          }}>{'Login'}</a></div>
      );
    }
    return (
      <form onSubmit={e => {
        e.preventDefault();
        this.props.login({
          password: md5(this.state.pwLoginInput),
          name: this.state.nameLoginInput
        }).then((data) => {
          console.log(data);
          changeSession(data.res.sessUuid)
          window.location = '/';
        });
      }}>
        <table>
          <tbody>
            <tr>
              <td>
                <label>{'Name'}</label>
              </td>
              <td>
                <input type="text" onChange={e => this.setState({nameLoginInput: e.target.value})}/>
              </td>
            </tr>
            <tr>
              <td>
                <label>{'Password'}</label>
              </td>
              <td>
                <input type="password" onChange={e => this.setState({pwLoginInput: e.target.value})}/>
              </td>
            </tr>
          </tbody>
        </table>
        <button type="submit">{'Login'}</button>
      </form>
    )
  }
  renderUpdateProfile() {
    if (!this.state.updateOpen) {
      return (
        <a href={"blank"}
          onClick={e => {
            e.preventDefault();
            this.setState({
              updateOpen: true
            });
          }}>
          {'Update Profile'}
        </a>
      );
    }
    return (
      <form onSubmit={e => {
        e.preventDefault();
        let changes = {};
        if (this.state.nameInput) {
          changes.name = this.state.nameInput
        }
        if (this.state.pwInput) {
          changes.password = md5(this.state.pwInput)
        }
        this.props.updateUser(changes);
      }}>
        <table>
          <tbody>
            <tr>
              <td>
                <label>{'Name'}</label>
              </td>
              <td>
                <input type="text" onChange={e => this.setState({nameInput: e.target.value})}/>
              </td>
            </tr>
            <tr>
              <td>
                <label>{'Password'}</label>
              </td>
              <td>
                <input type="text" onChange={e => this.setState({pwInput: e.target.value})}/>
              </td>
            </tr>
          </tbody>
        </table>
        <button type="submit">{'Submit'}</button>
      </form>
    )
  }
  render() {
    return (
      <div>
        <div><a href="/">{'Home'}</a></div>
        <div>{this.props.me && this.props.me.name}</div>
        <div>{`${this.props.karma} Karma`}</div>
        <div><a href="/u/verify">{'Verify'}</a></div>
        {this.renderLogin()}
        {this.renderUpdateProfile()}
        <div>
          {this.props.modals.filter(m => m.dismissed).map(m => {
            return (
              <a href onClick={e => {
                this.props.undismissModal({
                  id: m.id
                });
              }}>
                {m.id}
              </a>
            );
          })}
        </div>
        <div style={{
          marginTop: '24px'
        }}>
          <strong>{'Messages'}</strong>
          {this.props.messages.map(message => {
            return (
              <div onClick={() => {
                this.props.markMessageAsRead({
                  id: message.id
                });
              }}>
                <div>
                  {renderMessage(message.content)}
                </div>
                <div>
                  {`read: ${message.readState}`}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
