const VOTE_TYPES = {
  up: [{
    icon: '😍',
    dbType: 'heart',
    value: 1
  }, {
    icon: '😂',
    dbType: 'crylaugh',
    value: 1
  }, {
    icon: '🤔',
    dbType: 'think',
    value: 1
  }],
  down: [{
    icon: '😢',
    dbType: 'cry',
    value: -1
  }, {
    icon: '😡',
    dbType: 'angry',
    value: -1
  }]
};

export default VOTE_TYPES;
