import React, {Component} from 'react';
import NestedComment from './nested-comment';
import CommentForm from './comment-form';

export default class RecursiveCommentSection extends Component {
  static defaultProps = {
    comments: []
  }
  render() {
    return (
      <div>
        {(this.props.comments[0] || {Children: []}).Children.map(c => {
          return (
            <NestedComment comment={c} />
          );
        })}
        <CommentForm parentId={(this.props.comments[0] || {}).id} />
      </div>
    );
  }
}
