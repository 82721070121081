import uuid from 'uuidv4';
import {
  createSession,
  getMe
} from './actions/xhr-actions';
const quipySessKey = '_qsess';

/* eslint-disable no-restricted-globals */
export default function sessionCookieInit(store) {
  const doCreateSession = () => {
    sessVal = uuid();
    document.cookie = `${quipySessKey}=${sessVal}; path=/`
    store.dispatch(createSession({key: sessVal})).then(() => {
      location && location.reload();
    });
  }
  let sessVal = '';
  const qSessCookie = document.cookie.split(';')
    .find(c => c.indexOf(quipySessKey) > -1)

  if (qSessCookie) {
    sessVal = qSessCookie.split('=')[1];
  }
  if (sessVal.trim().length < 1) {
    doCreateSession();
  } else {
    store.dispatch(getMe()).catch(doCreateSession);
  }
  return sessVal;
}

export function changeSession(key) {
  document.cookie = `${quipySessKey}=${key}; path=/`
  return key;
}
