import React, {Component} from 'react';

export default class RoomManager extends Component {
  static defaultProps = {
    rooms: []
  }
  constructor() {
    super();
    this.state = {}
  }
  componentDidMount() {
    this.props.getRooms();
  }
  render() {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>
                {'Name'}
              </th>
              <th>
                {'Actions'}
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.rooms.map(r => {
              return (
                <tr>
                  <td>
                    {r.name}
                  </td>
                  <td>
                    <a onClick={e => {
                      this.props.deleteRoom({
                        id: r.id
                      })
                    }}>
                      {'Delete'}
                    </a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
