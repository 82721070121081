import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  uploadPostFile
} from '../actions/upload';
import {
  getVerifications,
  createVerification
} from '../actions/xhr-actions';

const VERIFY_VID_URL = "/api/public-bucket/Quick-verify.mp4";

const eyeDirection = [
  'up', 'right', 'left', 'down'
][Math.floor(Math.random() * 4)];

const fingersHeld = [
  'one finger', 'two fingers', 'three fingers', 'four fingers',
  'five digits'
][Math.floor(Math.random() * 5)];

const GOOGLE_API_KEY = 'AIzaSyBnOrk4JdiNnJRConmFMorR5fJwgkkAM4I';

function initMap() {

}

class VerifyTool extends Component {
  constructor() {
    super();
    this.state = {};
    this.handleStraightSelfie = this.handleStraightSelfie.bind(this);
    this.handleGenSelfie = this.handleGenSelfie.bind(this);
  }
  componentDidMount() {
    this.props.getVerifications();
    navigator.geolocation.getCurrentPosition((pos) => {
      this.setState({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      });
    })
  }
  handleStraightSelfie(e) {
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }).then(base64 => {
      this.setState({
        straightSelfieUri: base64
      });
    });
    this.setState({
      uploadInprogress: true,
      progressTotal: 100,
      progressLoaded: 1});
    this.props.uploadPostFile({

    }, e.target.files[0], {
      onprogress: e => {
        this.setState({
          progressTotal: e.total,
          progressLoaded: e.loaded
        });
      }
    }).then(fileAction => {
      const thumbFile = fileAction.payload.res[0];
      const fileName = `quip://file/${thumbFile.storageKey}`;
      this.props.createVerification({
        type: 'straight',
        fileStorageKey: fileAction.payload.res[2].storageKey
      });
    });
  }
  handleGenSelfie(e) {
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }).then(base64 => {
      this.setState({
        genSelfieUri: base64
      });
    });
    this.props.uploadPostFile({

    }, e.target.files[0], {
      onprogress: e => {
        this.setState({
          progressTotal: e.total,
          progressLoaded: e.loaded
        });
      }
    }).then(fileAction => {
      const thumbFile = fileAction.payload.res[0];
      const fileName = `quip://file/${thumbFile.storageKey}`;
      this.props.createVerification({
        type: 'gen',
        content: JSON.stringify({eyeDirection, fingersHeld}),
        fileStorageKey: fileAction.payload.res[2].storageKey
      });
    });
  }
  renderStraightVerification() {
    const straightV = this.props.verifications.find(v => {
      if (v.type === 'straight') {
        return true;
      }
    });
    if (straightV) {
      return (
        <div>
          <img src={`/api/file/${straightV.fileStorageKey.replace('_1000', '_100')}`} />
          {straightV.state}
        </div>
      );
    }
  }
  renderGenVerification() {
    const genV = this.props.verifications.find(v => {
      if (v.type === 'gen') {
        return true;
      }
    });
    if (genV) {
      return (
        <div>
          <img src={`/api/file/${genV.fileStorageKey.replace('_1000', '_100')}`} />
          <div>
            {genV.state}
          </div>
          <div>
            {genV.content}
          </div>
        </div>
      );
    }
  }
  render() {
    return (
      <div>
        <h1>{'How to verify for Quipy'}</h1>
        <div>
          <video height={'300px'} src={VERIFY_VID_URL} controls></video>
        </div>
        <div>
          <h2>{'1)'}</h2><p>{'Submit a straight Selfie'}</p>
          <form>
            <input type="file" onChange={this.handleStraightSelfie}/>
          </form>
          <div>
            {this.state.straightSelfieUri ?
              <img src={this.state.straightSelfieUri} /> : null}
          </div>
          <div>
            {this.renderStraightVerification()}
          </div>
        </div>
        <div>
          <h2>{'2)'}</h2><p>
            {'Submit a selfie with your eyes looking '}
            <strong>{eyeDirection}</strong>
            {' and '}
            <strong>{fingersHeld}</strong>
            {' held up.'}</p>
          <form>
            <input type="file" onChange={this.handleGenSelfie}/>
          </form>
          <div>
            {this.state.genSelfieUri ?
              <img src={this.state.genSelfieUri} /> : null}
          </div>
          <div>
            {this.renderGenVerification()}
          </div>
        </div>
        <div>
          <h2>{'3)'}</h2><p>{`Select your typical location.`}</p>
          <iframe
            width="600"
            height="450"
            frameborder="0" style={{border: 0}}
            src={`https://www.google.com/maps/embed/v1/view?key=${GOOGLE_API_KEY}&center=${this.state.lat},${this.state.lng}&zoom=16`}
            allowfullscreen>
          </iframe>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  verifications: state.verifications.verifications
}), {
  uploadPostFile, getVerifications, createVerification
})(VerifyTool);
