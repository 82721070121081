import React, {Component} from 'react';
import {withRouter} from 'react-router';
import MarkdownRenderer from 'react-markdown-renderer';

const DELIN = '%TEXTDELINEATE%';

class PostRow extends Component {
  getTitle() {
    const [title, body] = this.props.post.content.split(DELIN);
    return title;
  }
  getBody() {
    const [title, body] = this.props.post.content.split(DELIN);
    return body;
  }
  renderShortView() {
    // [characterLength, fontSize]
    const titleText = this.getTitle()
    const fontSize = [
      [10, '2em'],
      [20, '1.8em'],
      [50, '1.3em'],
      [100, '1.2em'],
      [500, '1em'],
    ].find(s => {
      return s[0] > titleText.length;
    })[1];

    return (
      <div>
        <strong style={{fontSize}}>{titleText}</strong>
      </div>
    );
  }
  render() {
    if (!this.props.post.content) {
      return <div>'MISSING CONTENT'</div>
    }
    if (!this.props.longView) {
      return this.renderShortView();
    }
    return (
      <div>
        {this.renderShortView()}
        <MarkdownRenderer markdown={this.getBody()} />
      </div>
    );
  }
}

export default withRouter(PostRow);
