import React, {Component} from 'react';
import parse from 'url-parse';
import {withRouter} from 'react-router';

class PostRow extends Component {
  render() {
    return (
      <div>
        <iframe style={{border: 'none'}} src={`https://twitframe.com/show?url=${encodeURI(this.props.post.content)}`} />
        <div>
          <a href={`/q/${
              this.props.match.params.roomName
            }/${this.props.post.id}`}>
            {'View Comments'}
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(PostRow)
