import React, {Component} from 'react';
import ArrowIcon from './arrow-icon';
import {
  voteOnPost,
  getPosts
} from '../../actions/xhr-actions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import VOTE_TYPES from '../../vote-types.js'

const emojiUpValues = VOTE_TYPES.up.map(emoji => emoji.dbType);
const emojiDownValues = VOTE_TYPES.down.map(emoji => emoji.dbType);


class PostVoter extends Component {
  updatePosts() {
    /*this.props.getPosts({
      roomName: this.props.match.params.roomName || 'root'
    });*/
  }
  constructor() {
    super();
    this.state = {};
    this.handleUpOver = this.handleUpOver.bind(this);
    this.handleDownOver = this.handleDownOver.bind(this);
    this.handleOut = this.handleOut.bind(this);
    this.invalidateHover = this.invalidateHover.bind(this);
    this.handleUpVote = this.handleUpVote.bind(this);
    this.handleDownVote = this.handleDownVote.bind(this);
  }
  getUpVoteColor() {
    return this.props.post.myVote &&
      this.props.post.myVote.value === 1 ? 'orange' : 'grey';
  }
  getDownVoteColor() {
    return this.props.post.myVote &&
      this.props.post.myVote.value === -1 ? 'blue' : 'grey';
  }
  handleUpVote(e) {
    this.props.voteOnPost({
      postId: this.props.post.id,
      value: this.props.post.myVote && this.props.post.myVote.value === 1 ? 0 : 1
    }).then(() => this.updatePosts());
  }
  handleDownVote(e) {
    this.props.voteOnPost({
      postId: this.props.post.id,
      value: this.props.post.myVote && this.props.post.myVote.value === -1 ? 0 : -1
    }).then(() => this.updatePosts());
  }
  handleUpOver(e) {
    const token = Math.random();
    this.upover = token;
    setTimeout(() => {
      if (token === this.upover) {
        this.setState({upEmoji: true});
      }
    }, 700);
  }
  handleOut(e) {
    if (this.state.upEmoji) {
      this.setState({upEmoji: false});
    }
    if (this.state.downEmoji) {
      this.setState({downEmoji: false});
    }
  }
  handleEmojiVote(emoji) {
    return e => {
      if (this.props.post.myVote) {
        if (this.props.post.myVote.type === emoji.dbType &&
          this.props.post.myVote.value === emoji.value) {
            return this.invalidateVote();
        }
      }
      this.props.voteOnPost({
        postId: this.props.post.id,
        type: emoji.dbType,
        value: emoji.value
      }).then(() => this.updatePosts());
    }
  }
  invalidateVote() {
    this.props.voteOnPost({
      postId: this.props.post.id,
      type: 'null',
      value: 0
    }).then(() => this.updatePosts());
  }
  invalidateHover() {
    this.downout = null;
    this.upover = null;
  }
  handleDownOver(e) {
    const token = Math.random();
    this.downout = token;
    setTimeout(() => {
      if (token === this.downout) {
        this.setState({downEmoji: true});
      }
    }, 700);
  }
  renderUpEmoji() {
    if (!this.state.upEmoji) {
      return null;
    }
    return (
      <div style={{
        position: 'absolute',
        padding: 8,
        bottom: '42px',
        whiteSpace: 'nowrap',
        paddingBottom: '24px',
        left: '-8px'}}
        onMouseLeave={this.handleOut}>
        <div style={{
          backgroundColor: 'white',
          cursor: 'pointer',
          borderRadius: '8px',
          padding: '4px',
          fontSize: '2.5em'
        }}>
          {VOTE_TYPES.up.map(emoji => {
            return (
              <span className="vote-icon"
                onClick={this.handleEmojiVote(emoji)}>
                {emoji.icon}
              </span>
            );
          })}
        </div>
      </div>
    )
  }
  renderDownEmoji() {
    if (!this.state.downEmoji) {
      return null;
    }
    return (
      <div style={{
        position: 'absolute',
        padding: 8,
        paddingTop: '50px',
        whiteSpace: 'nowrap',
        top: '24px',
        left: '0px'}}
        onMouseLeave={this.handleOut}>
        <div style={{
          backgroundColor: 'white',
          borderRadius: '8px',
          padding: '4px',
          fontSize: '2.5em',
          cursor: 'pointer'
        }}>
          {VOTE_TYPES.down.map(emoji => {
            return (
              <span className="vote-icon"
                onClick={this.handleEmojiVote(emoji)}>
                {emoji.icon}
              </span>
            );
          })}
        </div>
      </div>
    )
  }
  renderEmojiShare() {
    if (!this.props.post.emojiShare) {

    }
    return (
      <div style={{
        whiteSpace: 'nowrap',
        position: 'relative',
        zIndex: -3,
        marginLeft: '4px'
      }}>
        {Object.keys(this.props.post.emojiShare)
          .sort((a, b) => {
            const av = this.props.post.emojiShare[a];
            const bv = this.props.post.emojiShare[b];
            return av > bv ? -1 : av < bv ? 1 : 0;
          })
          .map((k, i) => {
            return (
              <span style={{
                left: `${i * 12}px`,
                position: 'absolute'
              }}>
                {(VOTE_TYPES.up.find(e => e.dbType === k) || {}).icon}
                {(VOTE_TYPES.down.find(e => e.dbType === k) || {}).icon}
              </span>
            )
          })}
      </div>
    )
  }
  renderUpArrow() {
    const voteType = VOTE_TYPES.up.find(e => this.props.post.myVote &&
      e.dbType === this.props.post.myVote.type)
    return (
      <div
        onMouseOver={this.handleUpOver}
        onMouseLeave={this.invalidateHover}
        style={{cursor: 'pointer'}}
        >
        {voteType ?
          <span style={{
            position: 'absolute',
            left: '8px',
            zIndex: -2,
            fontSize: '28px',
            top: '-15px'
          }}>
            {voteType.icon}
          </span> : null}
        <ArrowIcon width={20} height={20}
          fill={this.getUpVoteColor()}
          onClick={this.handleUpVote}/>
      </div>
    );
  }
  renderDownArrow() {
    const voteType = VOTE_TYPES.down.find(e => this.props.post.myVote &&
      e.dbType === this.props.post.myVote.type)
    return (
      <div
        onMouseOver={this.handleDownOver}
        onMouseLeave={this.invalidateHover}
        style={{cursor: 'pointer'}}>
        {voteType ?
          <span style={{
            position: 'absolute',
            left: '-16px',
            zIndex: -2,
            fontSize: '28px',
            top: '44px'
          }}>
            {voteType.icon}
          </span> : null}
        <ArrowIcon width={20} height={20}
          transform="rotate(180 250 250)"
          fill={this.getDownVoteColor()}
          onClick={this.handleDownVote}/>
      </div>
    );
  }
  render() {
    return (
      <div style={{
        width: "48px", textAlign: "center", position: "relative"}}
        onMouseLeave={this.invalidateHover}>
        {this.renderUpEmoji()}
        {this.renderUpArrow()}
        {this.props.post.votescore}
        {this.renderDownArrow()}
        {this.renderDownEmoji()}
        {this.renderEmojiShare()}
      </div>
    )
  }
}

export default connect(state => ({}), {
  voteOnPost,
  getPosts
})(withRouter(PostVoter))
