const defaultState = {
  rooms: [],
  room: null
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'GET_ROOMS_SUCCESS':
    return {
      ...state,
      rooms: action.payload.res
    }
  case 'GET_ROOM_SUCCESS':
    return {
      ...state,
      room: action.payload.res
    }
  default:
    return state;
  }
}
