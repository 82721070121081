import AdminView from './admin-view';
import {connect} from 'react-redux';
import {
  updateUser,
  getUsers,
  login,
  getMe,
  updateUserRole
} from '../../actions/xhr-actions';

export default connect(state => ({
  users: state.users.users
}), {
  updateUser,
  getUsers,
  login,
  getMe,
  updateUserRole
})(AdminView);
