const defaultState = {
  sessionKey: null
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'LOAD_USER_FROM_COOKIES':
    return {
      ...state,
      sessionKey: action.payload.sess
    }

  case 'CREATE_SESSION_SUCCESS':
    return {
      ...state,
      sessionKey: action.payload.sess
    }
  default:
    return state;
  }
}
