import React, {Component} from 'react';
import './App.css';
import Routes from './components/routes';
import NotificationStack from './components/notifications';
import ModalStack from './components/modals';

class App extends Component {
  render() {
    return (
      <div className="App">
        <ModalStack />
        <div style={{
          margin: '4px 8px'
        }}>
          <Routes />
        </div>
        <NotificationStack />
      </div>
    );
  }
}

export default App;
