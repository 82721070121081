import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import HomePage from './home-page';
import CreateRoom from './create-room';
import RoomView from './room-view';
import PostView from './post-view';
import CreatePostView from './create-post';
import RoomManager from './room-manager';
import MeView from './me';
import AdminView from './admin';
import UserView from './user-view';
import VerifyTool from './verify-tool';
import Privacy from './privacy';
import TOS from './tos';

export default class AppRouter extends Component {
  render() {
    return (
      <Router>
        <Route path="/" exact>
          <Redirect to="/q/root" />
        </Route>
        <Route path="/i/privacy" exact component={Privacy} />
        <Route path="/i/tos" exact component={TOS} />
        <Route path="/u/rooms" exact component={RoomManager} />
        <Route path="/u/create-room" exact component={CreateRoom} />
        <Route path="/u/admin" exact component={AdminView}/>
        <Route path="/u/me" exact component={MeView} />
        <Route path="/u/verify" exact component={VerifyTool} />
        <Route path="/u/:userName" exact component={UserView} />
        <Route path="/q/:roomName" exact component={RoomView} />
        <Route path="/q/:roomName/create-post" exact component={CreatePostView} />
        <Route path="/q/:roomName/:postId" exact component={PostView} />
      </Router>
    )
  }
}
