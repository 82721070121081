import React, {Component} from 'react';
import RecursiveCommentSection from './recursive-comment';
import CommentForm from './comment-form';

export default class CommentSection extends Component {
  static defaultProps = {
    comments: []
  }
  constructor() {
    super();
  }
  componentDidMount() {
    this.props.getComments({
      id: this.props.post.id
    });
  }
  render() {
    return (
      <div style={{backgroundColor: 'white', padding: '24px 0'}}>
        <RecursiveCommentSection comments={this.props.comments} />
      </div>
    );
  }
}
