import React, {Component} from 'react';
import ArrowIcon from '../../room-view/arrow-icon';
import {
  voteOnComment,
  getComments
} from '../../../actions/xhr-actions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

class CommentVoter extends Component {
  updatePosts() {
    /*this.props.getComments({
      id: this.props.match.params.postId
    });*/
  }
  getUpVoteColor() {
    return this.props.comment.myVote &&
      this.props.comment.myVote.value === 1 ? 'orange' : 'grey';
  }
  getDownVoteColor() {
    return this.props.comment.myVote &&
      this.props.comment.myVote.value === -1 ? 'blue' : 'grey';
  }
  render() {
    return (
      <span style={{
        width: "20px", textAlign: "center",
        display: "inline-block"}}>
        <ArrowIcon width={20} height={20}
          fill={this.getUpVoteColor()}
          onClick={e => {
            this.props.voteOnComment({
              commentId: this.props.comment.id,
              value: this.props.comment.myVote &&
                this.props.comment.myVote.value === 1 ? 0 : 1
            }).then(() => this.updatePosts());
          }}/>
        {this.props.comment.votescore || 0}
        <ArrowIcon width={20} height={20}
          transform="rotate(180)"
          fill={this.getDownVoteColor()}
          onClick={e => {
            this.props.voteOnComment({
              commentId: this.props.comment.id,
              value: this.props.comment.myVote &&
                this.props.comment.myVote.value === -1 ? 0 : -1
            }).then(() => this.updatePosts());
          }}/>
      </span>
    )
  }
}

export default connect(state => ({}), {
  voteOnComment,
  getComments
})(withRouter(CommentVoter))
