import React, {Component} from 'react';
import RoomManager from './room-manager';
import {connect} from 'react-redux';
import {
  getRooms,
  getPosts,
  deleteRoom
} from '../../actions/xhr-actions';

export default connect(state => ({
  rooms: state.rooms.rooms
}), {
  getRooms,
  getPosts,
  deleteRoom
})(RoomManager);
