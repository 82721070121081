const {XMLHttpRequest} = window;

export function uploadFile(opts, file, callbacks) {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("file", file);

    for (var key in opts.body) {
      formData.append(key, opts.body[key]);
    }

    var xhr = new XMLHttpRequest();
    xhr.upload.onprogress = callbacks.onprogress;
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        resolve(xhr);
      }
    };
    xhr.open("POST", opts.uri);
    xhr.send(formData);
  });
}

export const uploadFileAction = (name) => (opts, file, callbacks = {}) => (dispatch) => {
  dispatch({
    type: `${name}_UPLOAD_START`,
    payload: {
      req: opts
    }
  });
  return uploadFile(opts, file, {
    onprogress: (e) => {
      dispatch({
        type: `${name}_UPLOAD_PROGRESS`,
        payload: {
          req: opts,
          progress: {
            total: e.total,
            loaded: e.loaded
          }
        }
      });
      if (callbacks.onprogress) {
        callbacks.onprogress(e);
      }
    }
  })
    .then((xhr) => {
      const action = {
        type: `${name}_UPLOAD_SUCCESS`,
        payload: {
          req: opts,
          res: JSON.parse(xhr.response)
        }
      };
      dispatch(action);
      return action;
    })
    .catch(() => {
      dispatch({
        type: `${name}_UPLOAD_FAILURE`,
        payload: {
          req: opts
        }
      });
    });
}

export const uploadPostFile = (opts, file, callbacks) => uploadFileAction('POST_FILE')({
  ...opts,
  uri: `/api/upload/postfile`
}, file, callbacks);
