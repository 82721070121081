import React, {Component} from 'react';
import parse from 'url-parse';
import {withRouter} from 'react-router';

function getWatchID(post) {
  const postUrl = parse(post.content.trim(), true);

  return postUrl.query.v;
}

class PostRow extends Component {
  render() {
    return (
      <div>
        <iframe style={{border: 'none'}} src={`https://youtube.com/embed/${getWatchID(this.props.post)}`} />
      </div>
    );
  }
}

export default withRouter(PostRow)
