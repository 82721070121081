
const defaultState = {
  modals: []
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'GET_MODALS_SUCCESS':
    return {
      ...state,
      modals: action.payload.res
    }
  case 'DISMISS_MODAL':
    return {
      ...state,
      modals: state.modals.map(m => {
        if (m.id === action.payload.id) {
          return {
            ...m,
            dismissed: true
          };
        }
        return m;
      })
    };
  case 'UNDISMISS_MODAL':
    return {
      ...state,
      modals: state.modals.map(m => {
        if (m.id === action.payload.id) {
          return {
            ...m,
            dismissed: false
          };
        }
        return m;
      })
    };
  default:
    return state;
  }
}
