const defaultState = {
  posts: []
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'GET_USER_POSTS_SUCCESS':
    return {
      ...state,
      posts: action.payload.res
    }
  default:
    return state;
  }
}
