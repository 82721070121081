import React, {Component} from 'react';
import RoomView from '../room-view';

export default class HomePage extends Component {
  render() {
    return (
      <div>
        <a href="create-room">{'Create Room'}</a>
        <RoomView {...this.props}/>
      </div>
    );
  }
}
