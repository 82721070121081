const defaultState = {
  me: null,
  unread: 0,
  karma: 0
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'GET_ME_SUCCESS':
    return {
      ...state,
      me: action.payload.res,

    };
  case 'GET_UNREAD_COUNT_SUCCESS':
    return {
      ...state,
      unread: action.payload.res
    };
  case 'GET_KARMA_SUCCESS':
    return {
      ...state,
      karma: action.payload.res
    };
  default:
    return state;
  }
}
