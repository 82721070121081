import uuid from 'uuidv4';
const defaultState = {
  notifications: []
};

const rpcFailureReducer = (state, action) => {
  return {
    ...state,
    notifications: [
      ...state.notifications,
      {
        uuid: uuid(),
        message: action.payload.err
      }
    ]
  }
}

const RPC_NOTIFICATIONS = [
  'CREATE_COMMENT',
  'CREATE_POST',
  'UPDATE_USER',
  'CREATE_ROOM',
  'VOTE_ON_POST',
  'VOTE_ON_COMMENT',
  'GET_POSTS'
];

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  if (RPC_NOTIFICATIONS.find(n => action.type === `${n}_FAILURE`)) {
    return rpcFailureReducer(state, action);
  }
  switch (action.type) {
  case 'DISMISS_NOTIFICATION':
    return {
      ...state,
      notifications: state.notifications.map(n => {
        if (n.uuid === action.payload.notifUuid) {
          return {
            ...n,
            dismissed: true
          };
        }
        return n;
      })
    }
  default:
    return state;
  }
}
