import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  dismissNotification
} from '../../actions/user-actions';
import CONSTANTS from '../../constants';

class NotificationStack extends Component {
  static defaultProps = {
    notifications: []
  }
  render() {
    return (
      <div style={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        padding: '24px'
      }}>
        {this.props.notifications.map(n => {
          if (n.dismissed) {
            return null;
          }
          return (
            <div
              style={{
                backgroundColor: CONSTANTS.RED,
                color: 'white',
                padding: '24px',
                position: 'relative'
              }}
              >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: '4px 8px',
                  cursor: 'pointer'
                }} onClick={() => {
                  this.props.dismissNotification(n.uuid);
                }}>
                {'X'}
              </div>
              {n.message}
            </div>
          )
        })}
      </div>
    );
  }
}

export default connect((state) => ({
  notifications: state.notifications.notifications
}), {
  dismissNotification
})(NotificationStack);
