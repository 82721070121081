import React, {Component} from 'react';
import CommentSection from './comment-section';
import PostRenderer from '../post-renderers';

const renderer = PostRenderer();

export default class PostView extends Component {
  constructor() {
    super();
  }
  componentDidMount() {
    this.props.getPost({
      id: this.props.match.params.postId
    });
  }
  renderAdminDelete() {
    if (this.props.me &&
      this.props.me.role !== 'admin') {
      return null;
    }
    return (
      <a href="blank"
        onClick={e => {
          e.preventDefault();
          this.props.deletePost({
            id: this.props.match.params.postId
          });
        }}>
        {'Delete'}
      </a>
    );
  }
  renderPost() {
    return (
      <div>
        <div style={{
          backgroundColor: 'white',
          padding: '4px 12px',
          marginBottom: '8px'
        }}>
          <a href={`/q/${this.props.match.params.roomName}`} style={{marginRight: '8px'}}>
            {`Back to room ${this.props.match.params.roomName}`}
          </a>
        </div>
        {this.renderAdminDelete()}
        <div style={{
          backgroundColor: 'white',
          padding: '24px 12px',
          marginBottom: '8px'}}>
          {renderer(this.props.post, {
            longView: true
          })}
        </div>
        <CommentSection post={this.props.post} />
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.props.post ? this.renderPost() : null}
      </div>
    );
  }
}
