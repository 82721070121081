const defaultState = {
  posts: [],
  isLoading: false
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'GET_POSTS_SUCCESS':
    return {
      ...state,
      posts: action.payload.res,
      isLoading: false
    };
  case 'GET_POSTS_START':
    return {
      ...state,
      isLoading: true
    };
  case 'VOTE_ON_POST_START':
    return {
      ...state,
      posts: state.posts.map((p, i) => {
        if (p.id === action.payload.req.postId) {
          return {
            ...p,
            votescore: parseInt(p.votescore) + action.payload.req.value - (p.myVote ? p.myVote.value : 0),
            myVote: action.payload.req
          }
        }
        return p;
      })
    }
  default:
    return state;
  }
}
