import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import createStore from './store';
import {Provider} from 'react-redux';
import documentReady from 'document-ready';
import sessionCookieInit from './session-cookie';
import {getMe} from './actions/xhr-actions';

documentReady(function() {
  const store = createStore();

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
  const sessVal = sessionCookieInit(store);
});
