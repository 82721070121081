import React, {Component} from 'react';
import CONSTANTS from '../../constants';

export default class Modal extends Component {
  render() {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, .1)'
        }}>
        <div
          style={{
            display: 'table',
            margin: '150px auto',
          }}>
          <div style={{
            minWidth: '200px',
            maxWidth: '75vh',
            backgroundColor: CONSTANTS.LIGHT,
            borderRadius: '16px',
            boxShadow: '1px 2px 8px 5px rgba(0, 0, 0, .3)',
            padding: '16px'
          }}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
