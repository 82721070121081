import React, {Component} from 'react';
import CommentForm from './comment-form';
import CommentVoter from './comment-voter';
import Timeago from '../../ui/timeago';

export default class NestedComment extends Component {
  constructor() {
    super();
    this.state = {reply: false};
  }
  renderCommentForm() {
    if (!this.state.reply) {
      return null;
    }
    return (
      <CommentForm parentId={this.props.comment.id} />
    );
  }
  render() {
    return (
      <div style={{
        borderLeft: '1px solid grey',
        margin: '8px 0 0 8px',
        padding: '8px 0 0 8px'
      }}>
        <div>
          <CommentVoter comment={this.props.comment} />
          <span style={{
            verticalAlign: 'top',
            marginLeft: '16px',
            display: 'inline-block'
          }}>
            {this.props.comment.content}
            {this.props.comment.Author ?
              <div>
                <a
                  style={{color: '#777'}}
                  href={`/u/${this.props.comment.Author.name}`}>
                  {this.props.comment.Author.name}
                </a>
                <Timeago date={this.props.comment.createdAt} />
              </div> : null}
            <button onClick={e => {
              this.setState({reply: true});
            }}>
              {'Reply'}
            </button>
          </span>
        </div>

        {this.renderCommentForm()}
        {this.props.comment.Children && this.props.comment.Children.map(c => {
          return (
            <NestedComment comment={c} />
          )
        })}

      </div>
    )
  }
}
