import React, {Component} from 'react';
import CONSTANTS from '../../constants';

export default class Spinner extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        display="block"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 100 100"
        style={{ margin: "auto" }}
      >
        <g transform="translate(50 50) scale(.7) translate(-50 -50) rotate(325.939 50 50)">
          <animateTransform
            attributeName="transform"
            dur="0.7575757575757576s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 50 50;360 50 50"
          ></animateTransform>
          <path
            fill={CONSTANTS.RED}
            fillOpacity="0.8"
            d="M50 50V0a50 50 0 0150 50z"
          ></path>
        </g>
        <g transform="translate(50 50) scale(.7) translate(-50 -50) rotate(154.436 50 50)">
          <animateTransform
            attributeName="transform"
            dur="1.0101010101010102s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 50 50;360 50 50"
          ></animateTransform>
          <path
            fill={CONSTANTS.DEEP_BLUE}
            fillOpacity="0.8"
            d="M50 50h50a50 50 0 01-50 50z"
          ></path>
        </g>
        <g transform="translate(50 50) scale(.7) translate(-50 -50) rotate(342.961 50 50)">
          <animateTransform
            attributeName="transform"
            dur="1.5151515151515151s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 50 50;360 50 50"
          ></animateTransform>
          <path
            fill={CONSTANTS.DARK}
            fillOpacity="0.8"
            d="M50 50v50A50 50 0 010 50z"
          ></path>
        </g>
        <g transform="translate(50 50) scale(.7) translate(-50 -50) rotate(171.479 50 50)">
          <animateTransform
            attributeName="transform"
            dur="3.0303030303030303s"
            keyTimes="0;1"
            repeatCount="indefinite"
            type="rotate"
            values="0 50 50;360 50 50"
          ></animateTransform>
          <path
            fill={CONSTANTS.LIGHT_BLUE}
            fillOpacity="0.8"
            d="M50 50H0A50 50 0 0150 0z"
          ></path>
        </g>
      </svg>
    );
  }
}
