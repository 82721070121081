import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {formValidator} from '../../validator';

const createRoomValidator = formValidator({
  roomName: {
    minLength: 3,
    alphabetOnly: true
  }
});

class CreateRoomPage extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      validations: {}
    };
  }
  renderErrors() {
    if (this.state.validations.isFormValid !== false) {
      return null;
    }
    return (
      <div>
        <ul style={{color: 'red'}}>
          {this.state.validations.validations.roomName.reasons.map(reason => {
            if (reason === 'alphabetOnly') {
              return (
                <li>
                  {'Use only alphabetic characters for room names. No spaces, numbers, or special characters.'}
                  <div>{'IE, instead of "Family\'s Playground" use "FamilyPlayground"'}</div>
                </li>
              )
            }
            if (reason === 'minLength') {
              return (
                <li>
                  {'Room names must be at least 3 characters long.'}
                  <div>{'IE, instead of "go" use "ago"'}</div>
                </li>
              )
            }
          })}
        </ul>
      </div>
    )
  }
  render() {
    return (
      <div>
        <form onSubmit={e => {
          e.preventDefault();
          const validations = createRoomValidator({
            roomName: this.state.name
          });
          console.log(validations);

          if (validations.isFormValid) {
            this.props.createRoom({
              name: this.state.name
            })
              .then(() => this.props.history.go(`/q/${this.state.name}`));
          } else {
            this.setState({
              validations: validations
            });
          }
        }}>
          <label>{'Name'}</label>
          <input type="text" value={this.state.name}
            onChange={e => this.setState({
              name: e.target.value
            })}/>
          {this.renderErrors()}
          <button type="submit">{'Submit'}</button>
        </form>
      </div>
    );
  }
}

export default withRouter(CreateRoomPage);
