import React, {Component} from 'react';
import PostRenderer from '../post-renderers';

const renderer = PostRenderer();

export default class AdminView extends Component {
  static defaultProps = {
    users: []
  }
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.props.getUserPosts({
      userName: this.props.match.params.userName
    });
  }
  render() {
    return (
      <div>
        <div><a href="/">{'Home'}</a></div>
        <div style={{
          marginTop: '24px'
        }}>
          {this.props.posts.map(post => {
            return renderer(post, {roomName: post.room.name});
          })}
        </div>
      </div>
    );
  }
}
