import React, {Component} from 'react';
import PostRenderer from '../post-renderers';
import PostVoter from './post-voter';
import Spinner from '../ui/spinner';
import SimplePostCreator from './simple-post-creator';
import CONSTANTS from '../../constants';

const renderer = PostRenderer();

class RoomView extends Component {
  static defaultProps = {
    posts: [],
    rooms: []
  }

  constructor(props) {
    super(props);
    this.state = {
      input: ''
    };
  }

  componentDidMount() {
    this.props.getRooms();
    this.props.getRoom({
      id: this.props.match.params.roomName
    });
    this.props.getUnreadCount();
    this.props.getKarma();
    this.props.getPosts({
      roomName: this.props.match.params.roomName || 'root'
    });
  }

  getMeLabel() {
    if (this.props.me) {
      return this.props.me.name;
    }
    return 'My Settings'
  }

  renderAdminDelete() {
    if ((this.props.me &&
      this.props.me.role !== 'admin') ||
      !this.props.room) {
      return null;
    }
    return (
      <a href="blank"
        onClick={e => {
          e.preventDefault();
          this.props.deleteRoom({
            id: this.props.room.id
          });
        }}>
        {'Delete'}
      </a>
    );
  }

  render() {
    return (
      <div className="App">
        <div style={{backgroundColor: 'white', padding: '4px 12px'}}>
          <div>
            <img style={{width: '54px', height: '54px'}} src="/mascot-bubble.png" />
            <div style={{display: 'inline-block', marginLeft: '12px'}}>
              <span style={{lineHeight: '54px', verticalAlign: 'top', display: 'inline-block'}}>
                <a style={{marginRight: '8px'}} href={'/u/create-room'}>{'Create Room'}</a>
                <a style={{marginRight: '8px'}} href={'/i/privacy'}>{'Security'}</a>
                <a style={{marginRight: '8px'}} href={'/u/me'}>{`${this.getMeLabel()}`}</a>
                <span style={{marginRight: '8px'}}>{`${this.props.karma} Points`}</span>
                {this.props.unread === 1 ?
                  <a style={{marginRight: '8px', color: CONSTANTS.RED}} href={'/u/me'}>{'1 unread message'}</a> : null}
                  {this.props.unread > 1 ?
                    <a style={{marginRight: '8px', color: CONSTANTS.RED}} href={'/u/me'}>{
                      `${this.props.unread} unread messages`}
                    </a> : null}
                <a style={{marginRight: '8px'}} href={'/u/admin'}>{'Admin'}</a>
                {this.renderAdminDelete()}
              </span>
              <div style={{
                overflow: 'auto'
              }}>
                {'Rooms: '}
                {this.props.rooms.map(r => {
                  return (
                    <a href={`/q/${r.name}`} style={{
                      marginRight: '8px',
                      fontWeight: r.name === this.props.match.params.roomName ? 600 : 400}}>
                      {r.name}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>

        </div>
        {this.props.arePostsLoading ?
          <div>
            <div style={{
              margin: '0 auto',
              display: 'table'
            }}>
              <img
                style={{width: '400px', height: '400px'}}
                src={'/mascot-phone.png'} />
            </div>
            <Spinner />
          </div> : null}
        <SimplePostCreator />
        <table style={{marginTop: '24px', width: '100%', borderSpacing: '0px 8px'}} className="posts">
          <thead>
          </thead>
          <tbody>
              {this.props.posts.map(post => {
              return (
                <tr style={{
                  backgroundColor: 'white'
                }}>
                  <td style={{
                    borderBottom: '1px solid #eee',
                    width: '42px',
                    verticalAlign: 'top'}}>
                    <PostVoter post={post} />
                  </td>
                  <td style={{borderBottom: '1px solid #eee'}}>
                    {renderer(post, {roomName: this.props.match.params.roomName})}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default RoomView;
