import {combineReducers} from 'redux';
import postReducer from './posts';
import roomsReducer from './rooms';
import userSession from './session';
import postViewReducer from './post-view';
import commentsReducer from './comments';
import meReducer from './me';
import usersReducer from './users';
import messagesReducer from './messages';
import userPostsReducer from './user-posts';
import notifications from './notifications';
import modalsReducer from './modals';
import verificationsReducer from './verifications';

export default combineReducers({
  postView: postViewReducer,
  posts: postReducer,
  rooms: roomsReducer,
  modals: modalsReducer,
  session: userSession,
  comments: commentsReducer,
  me: meReducer,
  users: usersReducer,
  messages: messagesReducer,
  userPosts: userPostsReducer,
  notifications: notifications,
  verifications: verificationsReducer
});
