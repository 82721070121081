import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
  createComment
} from '../../../actions/xhr-actions';

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: ''
    };
  }
  render() {
    return (
      <form onSubmit={e => {
        e.preventDefault();
        console.log(this.props)
        this.props.createComment({
          roomName: this.props.match.params.roomName,
          parentId: this.props.parentId,
          content: this.state.input
        });
      }}>
        <textarea onChange={e => this.setState({input: e.target.value})}/>
        <button type="submit">
          {'Submit Comment'}
        </button>
      </form>
    )
  }
}

export default connect(state => ({}), {
  createComment
})(withRouter(CommentForm));
