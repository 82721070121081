import {connect} from 'react-redux';
import PostView from './post-view';
import {
  getPost,
  deletePost
} from '../../actions/xhr-actions';
export default connect(state => ({
  post: state.postView.post,
  me: state.me.me
}), {
  getPost,
  deletePost
})(PostView)
