import React from 'react';
import YoutubeRender from './youtube';
import TextRenderer from './text';
import TwitterRenderer from './twitter';
import QuipFileRenderer from './quip-file';
import QuipVideoRenderer from './quip-video';
import Timeago from '../ui/timeago';

export default function PostRenderer() {
  const renderers = [
    YoutubeRender,
    TwitterRenderer,
    QuipFileRenderer,
    QuipVideoRenderer,
    TextRenderer
  ]
  return (post, ctx) => {
    const Renderer = renderers.find(r => r.match(post, ctx));
    const props = {
      post,
      ...ctx
    };

    return (
      <div>
        {Renderer.render(post, ctx)}
        <div>
          <a style={{marginRight: '8px'}} href={`/u/${
              props.post.Author && props.post.Author.name
            }`}>
            {props.post.Author && props.post.Author.name}
          </a>
          {props.longView ? null :
            <a href={`/q/${
                props.roomName
              }/${props.post.id}`}>
              {props.post.countOfComments > 0 ?
                `${props.post.countOfComments} Comment${props.post.countOfComments > 1 ? 's' : ''}` :
                'Add a comment'}
            </a>}
          <Timeago date={props.post.createdAt} />
        </div>
      </div>
    );
  }
}
