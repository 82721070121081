

import React, {Component} from 'react';
import uuid from 'uuidv4';
const quipySessKey = '_qsess';

export default class AdminView extends Component {
  static defaultProps = {
    users: []
  }
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    this.props.getUsers();
  }
  loginAs() {
    const sessVal = uuid();
    this.props.login({
      name: this.state.loginUserInput,
      password: this.state.longPasswordInput,
      key: sessVal
    }).then(() => {
      document.cookie = `${quipySessKey}=${sessVal}; path=/`;
      this.props.getMe();
    });
  }
  render() {
    return (
      <div>
        <div><a href="/">{'Home'}</a></div>
        <div style={{
          marginTop: '24px'
        }}>
          {this.props.users.map(u => {
            return (
              <div>
                {u.name}
                <input type="checkbox"
                  checked={u.role === 'admin'}
                  onChange={e => {
                    this.props.updateUserRole({
                      role: e.target.checked ? 'admin' : 'user',
                      userId: u.id
                    });
                  }}/>
              </div>
            );
          })}
        </div>

        <form onSubmit={e => {
          e.preventDefault();
          this.loginAs();
        }}>
          <strong>{'Login As'}</strong>
          <input type="text" onChange={e => this.setState({loginUserInput: e.target.value})}/>
          <input type="text" onChange={e => this.setState({longPasswordInput: e.target.value})}/>
          <button type="submit">{'Submit'}</button>
        </form>
      </div>
    );
  }
}
