const defaultState = {
  messages: []
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'GET_MESSAGES_SUCCESS':
    return {
      ...state,
      messages: action.payload.res
    }
  default:
    return state;
  }
}
