import React from 'react';
import YoutubePostRow from './components/post-row';
import {
  deletePost
} from '../../../actions/xhr-actions';
import {connect} from 'react-redux';

const Component = connect(state => ({}), {
  deletePost
})(YoutubePostRow);

export default {
  match: post => post.content && post.content.indexOf('youtube.com') > -1,
  render: post => (
    <Component post={post} />
  )
};
