import React from 'react';
import PostRow from './components/post-row';
import {
  deletePost
} from '../../../actions/xhr-actions';
import {connect} from 'react-redux';

const Component = connect(state => ({}), {
  deletePost
})(PostRow);

export default {
  match: post => {
    return post.content && post.content.indexOf('quip://video/') === 0;
  },
  render: (post, props) => (
    <Component post={post} {...props} />
  )
};
