/*
* Generate an input validator
*
* @arg rule Object
*   param - alphabetOnly: bool
*   param - noSpaces: bool
*   param - maxLength: number
*   param - minLength: number
*
* @returns ValidationFunction
*   @input input String
*
*   @returns validations object
*     param - valid: bool
*     param - reasons: list<string>
*/
const validator = (rule) => {
  return (input) => {
    const resp = [];
    if (rule.alphabetOnly) {
      const lowerCodeLimit = 97;
      const upperCodeLimit = 122;
      if (!input
        .toLowerCase()
        .split('')
        .reduce((a, c) => {
          const code = c.charCodeAt(0);
          return a && (code > lowerCodeLimit && code < upperCodeLimit);
        }, true)) {
        resp.push('alphabetOnly');
      }
    }
    if (rule.noSpaces) {
      if (input && input.indexOf(' ') > -1) {
        resp.push('noSpaces');
      }
    }
    if (rule.maxLength) {
      if (input && input.length > rule.maxLength) {
        resp.push('maxLength');
      }
    }
    if (rule.minLength) {
      if (!input || input.length < rule.minLength) {
        resp.push('minLength');
      }
    }
    return {
      valid: resp.length === 0,
      reasons: resp
    };
  }
}

/*
  (Map<InputName => Rule>) => {
    (Map<InputName => InputValue>, isValid: Function, isInvalid: Function) => {
      isFormValid: Bool
      validations: ?Map<InputName => Validation>
    }
  }
*/

const formValidator = (inputRules) => {
  const validators = Object.keys(inputRules).map(k => {
    return {
      keyName: k,
      validator: validator(inputRules[k])
    };
  });
  return (inputValues) => {
    const resp = {
      isFormValid: true,
      validations: {}
    };
    validators.map(v => {
      resp.validations[v.keyName] = v.validator(inputValues[v.keyName]);
      resp.isFormValid = resp.isFormValid && resp.validations[v.keyName].valid ;
    });
    return resp;
  }
}

export default validator;

export {formValidator};
