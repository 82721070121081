const defaultState = {
  users: []
};

export default function (state, action) {
  if (!state) {
    return defaultState;
  }
  switch (action.type) {
  case 'GET_USERS_SUCCESS':
    return {
      ...state,
      users: action.payload.res
    }
  default:
    return state;
  }
}
