import {connect} from 'react-redux';
import CreateRoomView from './view';
import {
  createRoom
} from '../../actions/xhr-actions';

export default connect(
  state => state,
  {
    createRoom
  }
)(CreateRoomView);
