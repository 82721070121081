import React, {Component} from 'react';
import {withRouter} from 'react-router';
import windowSize from 'react-window-size';

const sizes = [
  100,
  300,
  1000
];

const portion = 0.8;
const margin = 100;

class PostRow extends Component {
  constructor() {
    super();
    this.state = {

    };
  }
  getContentUri() {
    let size = sizes.find(s => {
      return this.props.windowWidth < (s / portion) + margin;
    }) || 1000;
    if (!this.props.longView && !this.state.expanded) {
      size = 100;
    }
    return this.props.post.content
      .replace('quip://', '/api/')
      .replace('_100', `_${size}`);
  }
  render() {
    return (
      <div>
        {this.props.longView ? null :
          <div>
            <a
              href="blank"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  expanded: !this.state.expanded
                })
              }}>
              {this.state.expanded ? 'Shrink' : 'Expand'}
            </a>
          </div>}
        <div
          style={{
            maxHeight: this.props.longView || this.state.expanded ? null : '200px',
            overflow: 'auto'
          }}>
          <img src={this.getContentUri()}
            style={{
              maxWidth: '100%'
            }}/>
        </div>
      </div>
    );
  }
}

export default windowSize(withRouter(PostRow));
