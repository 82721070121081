import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from '../ui/modal';
import IndexModal from './premade';
import CloseIcon from '../ui/close-icon';
import {
  dismissModal
} from '../../actions/user-actions';

class ModalStack extends Component {
  static defaultProps = {
    modals: []
  }
  renderTopModal(modal) {
    if (!modal) {
      return null
    }
    if (modal.innerHTML) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: modal.innerHTML
          }} />
      );
    }
    const ModalComponent = IndexModal[modal.enum];
    return (
      <div>
        <ModalComponent
          dismissModal={this.props.dismissModal}
          modal={modal}
          me={this.props.me}/>
      </div>
    );
  }
  render() {
    const activeModal = this.props.modals.find(m => !m.dismissed);
    if (!activeModal) {
      return <div />;
    }
    return (
      <Modal>
        <div
          onClick={() => {
           this.props.dismissModal({
             id: activeModal.id
           });
         }}
         style={{
          width: '100%',
          height: '16px',
          direction: 'rtl'
        }}>
          <CloseIcon />
        </div>
        {this.renderTopModal(activeModal)}
      </Modal>
    );
  }
}

export default connect((state) => ({
  modals: state.modals.modals,
  me: state.me.me
}), {
  dismissModal
})(ModalStack);
