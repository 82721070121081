import {createStore, applyMiddleware, compose} from 'redux';
import reducers from './reducers';
import thunkMiddleware from 'redux-thunk';
import nestedProperty from 'nested-property';

const STORAGE_KEY = "quipy_ls";

const storedKeys = [
  'modals'
];

export default function createAppStore() {
  const middleware = [
    store => next => action => {
      const promise = next(action);
      const newState = store.getState();
      const storedState = storedKeys.reduce((a, k) => {
        nestedProperty.set(a, k, nestedProperty.get(newState, k));
        return a;
      }, {});
      try {
        const serializedState = JSON.stringify(storedState);
        if (serializedState !== localStorage.getItem(STORAGE_KEY)) {
          localStorage.setItem(STORAGE_KEY, serializedState);
        }
      } catch {}
      return promise;
    },
    thunkMiddleware
  ];
  let initialState = {};
  if (typeof(Storage) !== "undefined") {
    try {
      initialState = JSON.parse(localStorage.getItem(STORAGE_KEY));
    } catch {}
  }
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    reducers,
    initialState || {},
    composeEnhancers(
      applyMiddleware(...middleware)
    )
  );
}
