import {connect} from 'react-redux';
import CommentSection from './comment-section';
import {
  getComments
} from '../../../actions/xhr-actions';

export default connect(state => ({
  comments: state.comments.comments
}), {
  getComments
})(CommentSection)
