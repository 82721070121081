import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createPost} from '../../actions/xhr-actions';
import {withRouter} from 'react-router';

class SimplePostCreator extends Component {
  constructor() {
    super();
    this.state = {
      value: ''
    };
  }
  render() {
    return (
      <form
        style={{backgroundColor: 'white', padding: '4px 12px', margin: '8px 0'}}
        onSubmit={e => {
          e.preventDefault();
          this.props.createPost({
            content: this.state.value,
            room: this.props.match.params.roomName
          });
          this.setState({value: ''});
        }}>
        <label><strong>{'Create a post!'}</strong></label>
        <div>
          <input type="text"
            value={this.state.value}
            style={{fontSize: '24px'}}
            onChange={e => {
              this.setState({value: e.target.value});
            }}/>
          <div>
            <button type="submit">
              {"Submit"}
            </button>
            <a style={{marginLeft: '8px'}} href={`/q/${this.props.match.params.roomName}/create-post`}>{'Advanced Post Editor'}</a>
          </div>
        </div>
      </form>
    );
  }
}

export default connect(state => ({}), {
  createPost
})(withRouter(SimplePostCreator))
